import styled, { keyframes } from "styled-components";

import logo from "./assets/logo.png";
import { useEffect, useState } from "react";

const CIRCLES = 20;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledApp = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 2rem;
  gap: 3rem;
  max-width: 120rem;

  @media (max-width: 900px) {
    gap: 2rem;
    padding: 5rem 2.5rem;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: var(--bg);

  @media (max-width: 900px) {
    position: fixed;
    height: 100dvh;
  }
`;

const animatedGradient = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(150%, -75%);
  }
  50% {
    transform: translate(-75%, -150%);
  }
  75% {
    transform: translate(75%, 150%);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const Gradient = styled.div<{
  $color: string;
  $size: number;
  $top: number;
  $left: number;
  $delay: number;
  $duration: number;
}>`
  position: absolute;
  top: ${({ $top }) => $top}%;
  left: ${({ $left }) => $left}%;
  width: ${({ $size }) => $size}dvw;
  height: ${({ $size }) => $size}dvw;
  background: ${({ $color }) => $color};
  border-radius: 50%;
  filter: blur(15rem);
  animation: ${animatedGradient} ${({ $duration }) => $duration}s infinite;
  animation-delay: ${({ $delay }) => $delay}s;
  opacity: 0.15;

  @media (max-width: 900px) {
    filter: blur(6rem);
  }
`;

const Icon = styled.img`
  width: 18rem;

  @media (max-width: 900px) {
    width: 15rem;
  }
`;

const Header = styled.h1`
  font-size: 8rem;
  font-weight: 300;

  @media (max-width: 900px) {
    font-size: 6rem;
  }
`;

const SubHeader = styled.p`
  font-size: 2.2rem;
  font-weight: 400;
  max-width: 70rem;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 3rem;

  @media (max-width: 900px) {
    font-size: 1.8rem;
    max-width: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 900px) {
    gap: 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.9rem;
  line-height: 1.6;
  font-weight: 400;
  text-align: justify;

  @media (max-width: 900px) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

const Bold = styled.span`
  font-weight: 700;
  font-size: inherit;
`;

const Footer = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  gap: 3rem;
  margin-top: 7rem;

  @media (max-width: 900px) {
    margin-top: 5rem;
  }
`;

const Link = styled.a`
  font-size: 1.6rem;
  color: var(--main);
  text-decoration: underline;
`;

const App = () => {
  const [gradients, setGradients] = useState<
    {
      size: number;
      color: string;
      $top: number;
      $left: number;
      $duration: number;
      $delay: number;
    }[]
  >([]);

  useEffect(() => {
    const colors = ["#7CE296", "#ABF180", "#C2FC7E", "#DBB17D", "#F7FE85"];

    const newGradients = [];
    for (let i = 0; i < CIRCLES; i++) {
      newGradients.push({
        size: Math.random() * 30 + 20,
        color: colors[Math.floor(Math.random() * colors.length)],
        $top: Math.random() * 100,
        $left: Math.random() * 100,
        $duration: Math.random() * 30 + 30,
        $delay: Math.random() * 60,
      });
    }

    setGradients(newGradients);
  }, []);

  return (
    <Container>
      <Background>
        {gradients.map((gradient, index) => (
          <Gradient
            key={index}
            $size={gradient.size}
            $color={gradient.color}
            $top={gradient.$top}
            $left={gradient.$left}
            $duration={gradient.$duration}
            $delay={gradient.$delay}
          />
        ))}
      </Background>
      <StyledApp>
        <Icon src={logo} alt="icon" />
        <Header>Layer 0.5</Header>
        <Link
          href="https://etherscan.io/address/0x272671bBA718441B0d548259C7fFC07b3fa89Dee"
          target="_blank"
        >
          0x272671bBA718441B0d548259C7fFC07b3fa89Dee
        </Link>
        <SubHeader>
          We have witnessed horizontal scaling, we have seen vertical scaling.
          Now is the era of recursive scaling.
        </SubHeader>
        <Content>
          <Paragraph>
            An era of compromises and fragmentation finally comes to an end. We
            have witnessed horizontal scaling, we have seen vertical scaling,
            yet we are still here wondering when the scaling problem will
            actually be solved. The answer is simple: recursion. Layer 0.5
            brings you a megalithic recursive blockchain built completely on
            Ethereum mainnet. No more bridging. No more restaking. No more cheap
            transactions. Layer 0.5 brings everything back to Ethereum mainnet.
            L2s were a fun ride, but the adventure has to come to an end. The
            new paradigm of blockchain allows for blockchains within
            blockchains. How, you ask? Buckle up for a technical deep dive (VCs,
            please keep on reading; your time will come).
          </Paragraph>
          <Paragraph>
            Let us jointly abandon the lie of the decentralized sequencer once
            and for all. No need to even venture into the fantasy realm of
            shared sequencing. How to inherit mainnet security? Be on mainnet.
            Instead of dreaming of a world consisting of a plethora of L2s, why
            not embrace an L1 consisting of many L2s? That, my friend, is the
            vision of Layer 0.5. Each L2 could live in an Ethereum smart
            contract where each smart contract contains a complete
            implementation of the respective VM - all powered by Layer 0.5. To
            guarantee state-of-the-art security, we suggest that CertiK, as well
            as Peckshield, conduct a thorough and expensive code audit of Layer
            0.5.
          </Paragraph>
          <Paragraph>
            Beyond being the most secure system to date, Layer 0.5 offers a
            number of native and novel features. As everything runs on Ethereum
            mainnet, there is no longer a need to bridge to any other chain.
            Instead, users can enjoy embedded atomic bridges within Layer 0.5.
            At first, recursive gas costs may seem high; however, they will
            become negligible after successfully transitioning back to
            Proof-of-Work - a cause Layer 0.5 is firmly committed to.
          </Paragraph>
          <Paragraph>
            To ensure community involvement, Layer 0.5 will entirely rely on DAO
            governance, a battle-tested system for democratic participation (for
            an overview of successful DAOs in Web3, please refer to the list at
            the bottom of the page). There will be extensive governance theater,
            all secured by a multisig. For privacy reasons, all multisig members
            remain anonymous.
          </Paragraph>
          <Paragraph>
            Layer 0.5 will be governed and funded through $SEETHE. Note that the
            future team share of 1% will remain locked until the fully diluted
            valuation reaches $1 billion. At this point, a team of ten
            developers with a salary of $1 million each will commence
            development. We expect the launch to be imminent thereafter. To save
            costs during the early stages of rollout, we expect Charles
            Hoskinson to act as the initial node.
          </Paragraph>
          <Paragraph>
            For funding, Layer 0.5 will not rely on VCs but will solely be
            backed by insiders. However, Layer 0.5 recognizes the immense
            contribution VCs have had to the space since its inception. Hence, a
            generous and gratuitous airdrop allocation of points has been
            allocated to the most supportive VCs in the industry (as we are no
            experts in the VC space, we solely relied on an external source to
            identify the top of the class). Given their industry expertise, we
            suggest that the VCs included in the airdrop decide on the team
            composition by reaching Proof-of-Work consensus based on their
            timesheets from the two years running up to launch.
          </Paragraph>
          <Paragraph>
            We recognize that Layer 0.5 is a daunting project, but we believe it
            is the only solution that guarantees Ethereum's long-term success.
            Given the complex nature of our mission, we say: decentralize first,
            launch later.
          </Paragraph>
          <Paragraph>
            <Bold>Disclaimer:</Bold> For legal reasons, Layer 0.5 will only be
            accessible to US residents and citizens.
          </Paragraph>
        </Content>
        <Footer>
          <Link
            href="https://x.com/layer0point5"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </Link>
          <Link
            href="https://t.me/layer0point5"
            target="_blank"
            rel="noreferrer"
          >
            Telegram
          </Link>
          <Link
            href="https://etherscan.io/address/0x272671bBA718441B0d548259C7fFC07b3fa89Dee"
            target="_blank"
            rel="noreferrer"
          >
            Contract
          </Link>
          <Link
            href="https://dexscreener.com/ethereum/0x272671bba718441b0d548259c7ffc07b3fa89dee"
            target="_blank"
            rel="noreferrer"
          >
            DEX Screener
          </Link>
        </Footer>
      </StyledApp>
    </Container>
  );
};

export default App;
